<template>
  <tr>
    <td :label="$tc('name', 1)" property="featureName" width="200">
      {{ feature.featureName }}
      <el-tooltip v-if="feature.featureDescription" :content="feature.featureDescription" effect="dark" placement="right">
        <span>
          <i class="fad fa-info-circle text-info"></i>
        </span>
      </el-tooltip>
    </td>
    <td :label="$tc('has', 2)" property="featureValue">
      <plan-feature-value :feature="feature"></plan-feature-value>
    </td>
    <td :label="$tc('addOn')">
      <plan-feature-add-on :feature="feature" @update-value="$emit('update-value', $event)"></plan-feature-add-on>
    </td>
    <td :label="$tc('price')" align="right">
      <el-input v-model.number="feature.featureUnitPriceCustom" type="number"></el-input>
    </td>
    <td :label="$tc('total')" align="right">
      {{ feature.featureBilledValue | formatToCurrency }}
    </td>
  </tr>
</template>
<script>
export default {
  components: {
    planFeatureAddOn: _ => import('./plan-feature-add-on'),
    planFeatureValue: _ => import('./plan-feature-value'),
  },
  props: {
    feature: {
      required: true,
    },
    companyServiceFrequencyBilling: {
      required: true,
    },
  },
  computed: {
    companyServiceFrequencyBillingNumber: _this => {
      let number = 0
      switch (_this.companyServiceFrequencyBilling) {
        case 'Yearly': number = 12; break
        case 'Monthly': number = 1; break
        case 'Quarterly': number = 3; break
        case 'Semiannual': number = 6; break
        case 'ThreeYearly': number = 36; break
        case 'EighthYearly': number = 96; break
        default: number = 0
      }

      return number
    },
  },
  watch: {
    'feature.featureAddonQuantity': {
      handler(newValue) {
        this.calculateFeatureBilledValue()
      },
      immediate: true,
    },
    'feature.featureUnitPriceCustom': function (newValue) {
      this.calculateFeatureBilledValue()
    },
    companyServiceFrequencyBillingNumber(newValue) {
      this.calculateFeatureBilledValue()
    },
  },
  methods: {
    calculateFeatureBilledValue() {
      const _this = this
      let billedValue = 0
      if (_this.feature.featureAddonQuantity === 'true') {
        billedValue = +(_this.feature.featureUnitPriceCustom).toFixed(2)
      } else {
        billedValue = +(_this.feature.featureAddonQuantity * _this.feature.featureUnitPriceCustom).toFixed(2)
      }
      _this.$emit('update-billed-value', billedValue)
    },
  },
}
</script>
